import { useEffect, useState, useContext } from 'react';
import SkeletonLocation from './SkeletonLocation/SkeletonLocation';
import Slider from './Slider/Slider';
import SearchForm from '../../components/SearchForm/SearchForm';
import { SiteSettingsContext, CurrencyContext, SEOContext, SortContext } from '../../contexts/SiteSettingsProvider';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import useWindowSize from "utils/useWindowSize";
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import PropertiesList from 'pages/Location/Properties/PropertiesList.js';
import Filters from 'components/SearchForm/Filters/Filters';
import { Spinner } from "react-bootstrap";
import { Modal } from 'react-bootstrap';

const Location = ({ pageId, keyID }) => {
  const { sort: SortParam, order: OrderParam } = useContext(SortContext);
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const siteSettings = useContext(SiteSettingsContext);
  const { width } = useWindowSize();
  let [sliderInfo, setSliderInfo] = useState(null);
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [mapIsShwon, setMapVisability] = useState(true);
 
  const [propertyList, setPropertyList] = useState(null);
  const [googleMapAPIKey, setGoogleMapAPIKey] = useState('');
  const [propertyGridClasses, setPropertyGridClasses] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [sort, setSort] = useState('');
  const [total, setTotal] = useState('');
  const [dictionary, setDictionary] = useState(null);
  const [locationSummary, setLocationSummary] = useState('');
  const [introText, setIntroText] = useState('');
  const [displayMode, setDisplayMode] = useState("map");
  const [showIntroText, setShowIntroText] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [settings, setSettings] = useState(null);

  const [priceRangeSliderSettings, setPriceRangeSliderSettings] = useState(false);
  let [attributes, setAttributes] = useState(null);
  const [isFilters, setEnabledFilters] = useState(false);
  const [enablePriceRangeSlider, setEnablePriceRangeSlider] = useState(true);

  const [lastSortDirection, setLastSortDirection] = useState(null);
  const [lastSort, setLastSort] = useState(null);
  const [lastShow, setLastShow] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [lastClear, setLastClear] = useState(true);
  const [lastAttributes, setLastAttributes] = useState(null);
  const [lastChangeFlexibility, setLastChangeFlexibility] = useState(null);
  const [lastWeeklyPriceRange, setLastWeeklyPriceRange] = useState(null);
  // Check if locationSummary is empty and show introText instead
  const textToDisplay = locationSummary ? locationSummary : introText;

  // Show read more button only if locationSummary is not empty
  const showReadMoreButton = (locationSummary && introText) || introText.length > 772;

  const trimmedIntroText = introText.length > 772 ? `${introText.substring(0, 772)}...` : introText;

  useEffect(() => {
    setGoogleMapAPIKey(siteSettings.SiteSettings.GoogleMapAPIKey);
    if (width < window.lg) {
      setMapVisability(false);
    }
  }, []);

  useEffect(() => {
    if (mapIsShwon === false) {
      setDisplayMode('fullWidth');
    }
    if (mapIsShwon === true) {
      setDisplayMode('map');
    }
  }, [mapIsShwon, displayMode]);

  useEffect(() => {
    
    if (mapIsShwon){
      setPropertyGridClasses('tw-col-span-3 order-lg-1');
    } 
  }, [mapIsShwon]);
  

  const getContent = () => {
    setFetching(true);
    if (pageId) {
      fetch(`${process.env.REACT_APP_BASE_URL}apis/locationpage?pageid=${pageId}&clearcache=true`)
        .then(response => response.json())
        .then(data => {
          setPriceRangeSliderSettings(data.Settings?.SearchFormSettings?.PriceRangeSliderSettings);
          setEnabledFilters(data.Settings?.SearchFormSettings?.EnableAttributesFilter);
          setAttributes(data.Settings?.SearchFormSettings?.AttibutesSettings);
          setIntroText(data.Location.IntroText);
          setLocationSummary(data.Location.LocationSummary);
          setDictionary(data.Settings.Dictionaries);
          setSliderInfo(data);
          setFetching(false);
          setWidgets(data.Location.Widgets);
          setSettings(data.Settings.SearchFormSettings)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }

  const getProperties = (
    sortDirection = lastSortDirection,
    Sort = lastSort,
    Show = lastShow,
    Page = lastPage,
    clear = lastClear,
    Attributes = lastAttributes,
    ChangeFlexibility = lastChangeFlexibility,
    WeeklyPriceRange = lastWeeklyPriceRange 
  ) => {
    setLastSortDirection(sortDirection);
    setLastSort(Sort);
    setLastShow(Show);
    setLastPage(Page);
    setLastClear(clear);
    setLastAttributes(Attributes);
    setLastChangeFlexibility(ChangeFlexibility);
    setLastWeeklyPriceRange(WeeklyPriceRange);
    if (!clear){
      clear = true;
    }
    setFetching(true);
    if (pageId) {
      fetch(`${process.env.REACT_APP_BASE_URL}apis/searchproperties?pageid=${pageId}&location=${pageId}&clearcache=${clear}&SortDirection=${sortDirection || OrderParam}&Sort=${Sort || SortParam}&Currency=${currency}&Show=${Show}&Page=${Page}&Attributes=${Attributes}&ChangeFlexibility=${ChangeFlexibility}&WeeklyPriceRange=${WeeklyPriceRange}&Adults=2&Children=0&Infants=0`)
        .then(response => response.json())
        .then(data => {
         
          setPropertyList(data.Items);
          setTotal(data.Total);
          setFetching(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }

  useEffect(() => {
    getContent();
    getProperties();
  }, [pageId, currency])

  if (!sliderInfo) {
    return (
      <SkeletonLocation />
    );
  }

  function upMapState(val) {
   
    setMapVisability(!val);
  }

  function changeSorting(sort, order) {
    setSortDirection(order);
    setSort(sort);
    getProperties(order, sort);
  }

  const updateResults = (e) => {
    getProperties(sortDirection, sort, e.Show, e.Page, e.clearcache );
  }
 
  if (!sliderInfo){
    return  
  }

 
  if (!sliderInfo && !propertyList) {
    window.location = "/search/";
  }

  const transNightRange = (val) => {

  }

  const transWeekRange = (val) => {
    getProperties(undefined, undefined, undefined, undefined, undefined, undefined, undefined, val);
  }

  const changeFlexability = (val) => {
    getProperties(undefined, undefined, undefined, undefined, undefined, undefined, val);
  }

  const transferPriceMode = (val) => {
  
  }

  const transferAttrsOnLoad = (val) => {
     
  }

  const transferAttrsOnChange = (val) => {
 
    getProperties(undefined, undefined, undefined, undefined, undefined, val);
  }

 
  return (
    <>
 


      {(sliderInfo && sliderInfo.Location.Title)  && 

      <Slider url={sliderInfo?.Location?.MainImage?.Url}  >
        <div className="tw-px-3 lg:tw-bg-[#fff] tw-mt-3">
            <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings} />
        </div>
          <div class="tw-flex tw-justify-center tw-items-center  tw-ml-4 tw-mr-4 tw-mt-5 location-texts"  >
            <div className="lg:tw-w-2/3 slider-text tw-p-10 tw-shadow-xl  ">
              {sliderInfo?.Location?.Title && <h1 className="tw-mb-0 tw-pb-0 tw-text-3xl tw-text-white tw-font-bold  tw-mt-10 tw-font-light">{sliderInfo?.Location?.Title}</h1>}
              {sliderInfo?.Location?.SubTitle && <h2 className=" tw-text-xl tw-text-primary tw-font-light tw-font-bold  tw-mt-1 tw-mb-3">{sliderInfo?.Location?.SubTitle}</h2>}

              {(introText && textToDisplay) &&

                <div className='tw-items-left tw-gap-5 tw-text-white'>

                  <div dangerouslySetInnerHTML={{ __html: trimmedIntroText }}></div>
                  {showReadMoreButton && (
                    <button onClick={() => setShowModal(true)} className="lg:tw-w-[280px] tw-py-4 tw-px-10 search-form-btn field tw-bg-primary tw-text-white tw-uppercase">
                      Read more
                    </button>
                  )}
                  {showIntroText && (
                    <p dangesrouslySetInnerHTML={{ __html: introText }}></p>
                  )}


                  {/* {showReadMoreButton && (
                  <button onClick={() => setShowIntroText(!showIntroText)} className="tw-italic tw-pointer tw-border-t tw-border-b tw-border-primary tw-uppercase tw-min-w-[250px] tw-p-5">
                    {showIntroText ? 'Read less' : 'Read more'}
                  </button>
                )} */}
                </div>
              }
            </div>
          </div>

          <Modal show={showModal} size="lg" onHide={() => setShowModal(false)} centered scrollable={true} >
            <Modal.Header closeButton>
              <Modal.Title>{sliderInfo?.Location?.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p dangerouslySetInnerHTML={{ __html: introText }}></p>
              <p dangerouslySetInnerHTML={{ __html: locationSummary }}></p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary !tw-text-white" onClick={() => setShowModal(false)}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
      </Slider>}
      





      <div className="tw-p-3">



        {propertyList ? <div>
          <div className='md:tw-grid md:tw-grid-cols-6 tw-gap-3'>
            <div className="tw-w-full tw-pt-4 tw-pb-1 md:tw-pt-5 md:tw-pb-5">
              <div className="sm:tw-sticky tw-top-0 lg:tw-h-[100vh]">
                <Filters
                  changePriceMode={transferPriceMode}
                  changeAttrsOnLoad={transferAttrsOnLoad}
                  changeAttrsOnValueChange={transferAttrsOnChange}
                  changeNightRange={transNightRange}
                  changeWeekRange={transWeekRange}
                  //startSearchFromModal={startSearch}
                  attributesList={attributes}
                  enabledFilters={isFilters}
                  enablePriceRangeSlider={enablePriceRangeSlider}
                  priceRangeSliderSettings={priceRangeSliderSettings}
                  changeFlexability={changeFlexability}
                />
              </div>
            </div>

            {mapIsShwon ? <div className="tw-col-span-3 lg:tw-col-span-4">
              <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
              {isFetching ? <h6>  <Spinner variant="primary" animation="border" size="sm" /> We are looking the best properties for you </h6>
                : <h6>{total} Properties are available  </h6>}
              <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} isFetching={isFetching} total={total} sendParams={updateResults} dictionary={dictionary} />
            </div> :
              <div className="tw-col-span-4 order-lg-2"> 
                <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
                {propertyList && <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} total={total} sendParams={updateResults} dictionary={dictionary}  />}
              </div>
            }
            {
              (mapIsShwon && width > window.lg) ? <div className="lg:tw-columns-1 tw-col-span-1 ">
                <div className="tw-sticky tw-top-0">
                  
                  <div className="">
                    {propertyList &&
                      <Wrapper apiKey={googleMapAPIKey}  >
                        <SearchResultMap mapData={propertyList} />
                      </Wrapper>
                    }
                  </div>
                </div>
              </div> : null
            }

            {(mapIsShwon && width < window.lg) ?
              <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
                <div className="map">
                  <div className="">
                    {propertyList &&
                      <Wrapper apiKey={googleMapAPIKey}  >
                        <SearchResultMap mapData={propertyList} />
                      </Wrapper>
                    }
                  </div>
                </div>
              </div> : null
            }  
          </div>
        </div> : ''}

      </div>

      
    </>
  );
};



export default Location;

import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TemplateContext, TemplateTypeContext, PageIdContext, GetSiteDataContext } from '../../../contexts/SiteSettingsProvider';
import TemplateSkeleton from '../TemplateSkeleton/TemplateSkeleton'
import TemplateSelector from '../TemplateSelector/TemplateSelector';

function TemplateLoader() {
    const getSiteData = useContext(GetSiteDataContext);
    const templateType = useContext(TemplateTypeContext);
    const pageid = useContext(PageIdContext);
    const template = useContext(TemplateContext);

    const location = useLocation();

    const [pageId, setPageId] = useState(null);


    useEffect(() => {

        getSiteData();
    }, [location.pathname]);

    useEffect(() => {

        setPageId(pageid);

    }, [pageid, templateType]);



    if (!templateType || !pageId || !pageId) {
        return <TemplateSkeleton />;
    }

    // Render the template based on the templateType value
    return (
        <TemplateSelector key={pageId} templateType={templateType} pageId={pageId} template={template} />
    );
}

export default TemplateLoader;